import { API } from "aws-amplify";
import { getRedirect, listRedirects } from "../../graphql/queries";
import { createRedirect, deleteRedirect } from "../../graphql/mutations";

export const fetchRedirects = async (siteId: string) => {
  const apiData: any = await API.graphql({
    query: listRedirects,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      filter: {
        siteRedirectsId: {
          eq: siteId
        }
      }
    }
  });

  return apiData?.data?.listRedirects?.items;
}

export const fetchRedirect = async (redirectId: string) => {
  const apiData: any = await API.graphql({
    query: getRedirect,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      id: redirectId
    }
  });

  return apiData?.data?.getRedirect;
}

export const createRedirectAction = async ({ from, to, source, type, author, siteId, redirects }: any) => {
  let errors: any = {},
      redirectsarr: any = [];

  try {
    redirectsarr = JSON.parse(redirects);
  } catch (error) {
    return {
      redirectsDataError: 'Redirects data malformed'
    }
  }

  // Does a matching redirect already exist?
  const matchingRedirect = (path: FormDataEntryValue) => {
    const exists = redirectsarr ? redirectsarr.some(
      (redirect: any) => redirect.from === path || redirect.to === path
    ) : false;
    return exists;
  };

  if (matchingRedirect(from) || matchingRedirect(to) || !siteId) {
    errors.ruleAlreadyExists = 'A rule already exists for the URLs provided'
  }

  if (!from) {
    errors.from = 'No from path specified'
  }

  if (!to) {
    errors.to = 'No to path specified'
  }

  if (!type) {
    errors.type = 'No type specified'
  }

  // return data if we have errors
  if (Object.keys(errors).length) {
    return errors;
  }

  const addRedirectResult = await API.graphql({
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    query: createRedirect,
    variables: {
      input: {
        from: from,
        to: to,
        type: type,
        source: source,
        author: author,
        siteRedirectsId: siteId
      },
    },
  });

  return addRedirectResult;
}

export const deleteRedirectAction = async ({ redirectId }: any) => {
  let errors: any = {};

  if (!redirectId) {
    errors.redirectId = 'No id specified'
  }

  // return data if we have errors
  if (Object.keys(errors).length) {
    return errors;
  }

  const deleteRedirectResult = await API.graphql({
    authMode: 'AMAZON_COGNITO_USER_POOLS',
    query: deleteRedirect,
    variables: {
      input: {
        id: redirectId,
      }
    },
  });

  return deleteRedirectResult;
}