import React, { useState } from "react";
import { Input, Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { Form } from "react-router-dom";

interface IAddSiteFormProps {
  visible: boolean;
  close: (open: boolean) => void;
}

const AddSiteForm = () => {
  const [formVals, setFormVals] = useState([]);

  const rulesOnBlur = (e: any) => {
    e.preventDefault();
    setFormVals(e.target.value);
  };

  return (
    <>
      <Card className="max-w-100 pr-2 pl-2">
        <CardHeader>
          <h1 className="text-2xl mb-3">Add a new site</h1>
        </CardHeader>
        <div className="p-3 pb-5">
          <Form method="PUT">
            <div className="flex flex-wrap gap-2 py-2 justify-between">
              <div className="w-full pt-2">
                <Input
                  type="text"
                  label="Name"
                  name="name"
                  placeholder="Enter your site name"
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-2 py-2 justify-between">
              <div className="w-full pb-2">
                <Input
                  type="url"
                  label="URL"
                  name="url"
                  placeholder="Enter your site URL"
                />
              </div>
            </div>
            <Button type="submit" color="primary">
              Add site
            </Button>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default AddSiteForm;
