import { useAuthenticator } from '@aws-amplify/ui-react';
import { ReactNode } from 'react';
import Login from '../components/Login';

interface Props {
  children?: ReactNode
}

const RequireAuth = ({ children }: Props) => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route !== 'authenticated') {
    return <div><Login /></div>;
  }

  return children;
}

export default RequireAuth;
