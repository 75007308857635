/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      name
      url
      redirects {
        items {
          id
          from
          to
          source
          type
          author
          createdAt
          updatedAt
          siteRedirectsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accessKey
      storyblokSecret
      cmsToken
      cmsType
      cmsLinks
      buildHook
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        redirects {
          nextToken
          __typename
        }
        accessKey
        storyblokSecret
        cmsToken
        cmsType
        cmsLinks
        buildHook
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRedirect = /* GraphQL */ `
  query GetRedirect($id: ID!) {
    getRedirect(id: $id) {
      id
      site {
        id
        name
        url
        redirects {
          nextToken
          __typename
        }
        accessKey
        storyblokSecret
        cmsToken
        cmsType
        cmsLinks
        buildHook
        createdAt
        updatedAt
        owner
        __typename
      }
      from
      to
      source
      type
      author
      createdAt
      updatedAt
      siteRedirectsId
      owner
      __typename
    }
  }
`;
export const listRedirects = /* GraphQL */ `
  query ListRedirects(
    $filter: ModelRedirectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRedirects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        site {
          id
          name
          url
          accessKey
          storyblokSecret
          cmsToken
          cmsType
          cmsLinks
          buildHook
          createdAt
          updatedAt
          owner
          __typename
        }
        from
        to
        source
        type
        author
        createdAt
        updatedAt
        siteRedirectsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
