import React, { useState, useRef, Key } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  SelectItem,
  Input,
  Selection,
  Table,
  TableRow,
  TableCell,
  TableHeader,
  TableColumn,
  TableBody,
  Checkbox,
  Switch,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Textarea,
} from "@nextui-org/react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Form, useLoaderData, useSubmit } from "react-router-dom";
import { storyblokFetch } from "./storyblokFetch";

interface IStoryblokLinksProps {
  visible: boolean;
  close: (open: boolean) => void;
}

type LinkMethods = {
  [key: string]: any;
};

type LoaderData = {
  site: any;
};

const StoryblokLinks = ({ visible, close }: IStoryblokLinksProps) => {
  const { site } = useLoaderData() as LoaderData;
  const redirects = site.redirects;

  const { user } = useAuthenticator();
  const submit = useSubmit();
  const [valid, setValid] = useState(false);
  const [siteToken, setSiteToken] = useState("");
  const [siteType, setSiteType] = useState<Selection>(new Set(["storyblok"]));
  const [siteLinks, setSiteLinks] = useState<any>([]);
  const [auditLinks, setAuditLinks] = useState<any>([]);
  const [newRedirects, setNewRedirects] = useState<any>([]);

  const { cmsToken, cmsType, cmsLinks, name, url } = site;

  console.log("name", name);
  console.log("url", url);
  console.log("cmsLinks", cmsLinks);

  const linkMethods: LinkMethods = {
    storyblok: storyblokFetch,
  };

  const parseLinks = (links: string) => {
    let returnData;

    try {
      returnData = JSON.parse(links);
    } catch {
      returnData = [];
    }

    return returnData;
  };

  const auditLinkData = (newLinksArr: any, oldLinks: string) => {
    const oldLinksArr = parseLinks(oldLinks);

    // Create array of pages whereby URL has changed
    const updatedUrls = newLinksArr.filter((newLink: any) => {
      const oldLinkData = oldLinksArr.find(
        (oldLink: any) => oldLink.id === newLink.id
      );
      if (!oldLinkData) return false;

      const checkUrlsMatch = newLink?.slug === oldLinkData?.slug;
      return !checkUrlsMatch;
    });

    const urlDifferenceData = updatedUrls.map((link: any) => {
      const oldLinkUrl = oldLinksArr.find(
        (oldLink: any) => oldLink.id === link.id
      ).slug;
      return {
        oldUrl: oldLinkUrl,
        newUrl: link.slug,
        id: link.id,
      };
    });

    setAuditLinks(urlDifferenceData);
  };

  const fetchLinks = async () => {
    const siteTypeAsArray = Array.from(siteType as Set<Key>);
    const siteCmsType = `${siteTypeAsArray[0] || cmsType}`;
    const siteCmsToken = siteToken || cmsToken;

    if (!siteCmsType) {
      // @todo - show error
      return false;
    }

    if (!siteCmsToken) {
      // @todo - show error
      return false;
    }

    const linksMethod = linkMethods[siteCmsType] as any;
    if (!linksMethod) {
      // @todo - show error
      return false;
    }

    const linksFetch = await linksMethod(siteCmsToken);

    if (cmsLinks) auditLinkData(linksFetch, cmsLinks);

    setSiteLinks(linksFetch);
    setValid(true);
  };

  const formInvalid = !siteLinks || siteLinks.length < 1;

  const setRedirects = (e: any) => {
    const thisId = e.target.value;
    if (!thisId || !auditLinks) return false;

    const auditLink = auditLinks.find(
      (link: any) => link.id === parseInt(thisId, 10)
    );
    if (!auditLink) return false;

    const remove = newRedirects.some(
      (redirect: any) => redirect.id === auditLink.id
    );

    setNewRedirects(
      remove
        ? newRedirects.filter((redirect: any) => redirect.id !== auditLink.id)
        : [...newRedirects, auditLink]
    );
  };

  const redirectSelected = (redirectId: any) =>
    newRedirects.some(
      (redirect: any) => redirect.id === parseInt(redirectId, 10)
    );

  const slugExistsAsRedirect = (newUrl: string, oldUrl: string) => {
    const redirectsItems = redirects?.items;

    const exists = redirectsItems.some(
      (redirect: any) =>
        redirect.from === newUrl ||
        redirect.to === oldUrl ||
        redirect.from === oldUrl ||
        redirect.to === newUrl
    );

    return exists;
  };

  return (
    <>
      <Modal
        isOpen={visible}
        onOpenChange={close}
        placement="top-center"
        backdrop="blur"
        size="xl"
      >
        <ModalContent>
          {(onClose) => (
            <Form
              method="POST"
              onSubmit={(event) => {
                event.preventDefault();
                submit(event.currentTarget);
                close(false);
              }}
            >
              <ModalHeader className="flex flex-col gap-1">
                CMS Link Audit
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-wrap gap-2 py-2 px-1 justify-between">
                  <div className="w-full pt-2">
                    <p>
                      Retrieve URL data from your CMS and implement missing
                      redirects.
                    </p>
                  </div>

                  <input
                    type="hidden"
                    placeholder="Enter site name"
                    name="name"
                    id="name"
                    defaultValue={url || ""}
                  />

                  <input
                    type="hidden"
                    placeholder="Enter site URL"
                    name="url"
                    id="url"
                    defaultValue={name || ""}
                  />

                  <input
                    type="hidden"
                    name="author"
                    id="author"
                    value={user?.attributes?.email}
                  />

                  <div className="w-full pt-2">
                    <Select
                      name="cmsType"
                      label="CMS"
                      required
                      defaultSelectedKeys={["storyblok"]}
                      selectedKeys={siteType}
                      onSelectionChange={setSiteType}
                    >
                      <SelectItem key="storyblok" value="storyblok">
                        Storyblok
                      </SelectItem>
                    </Select>
                  </div>
                  <div className="w-full pt-2">
                    <Input
                      name="cmsToken"
                      required
                      label="Authentication Token"
                      defaultValue={cmsToken || ""}
                      onValueChange={setSiteToken}
                    />
                  </div>
                  <div className="w-full pt-2">
                    <input
                      type="hidden"
                      name="cmsLinks"
                      value={JSON.stringify(siteLinks) || ""}
                    />
                  </div>
                  <div className="w-full pt-2">
                    {auditLinks.length > 0 ? (
                      <div>
                        <Card isBlurred>
                          <CardHeader>
                            <p className="text-md">
                              You have {auditLinks.length} updated URLs.
                            </p>
                          </CardHeader>
                          <Divider />
                          <CardBody>
                            <p className="text-small text-default-500">
                              New slugs will only be flagged{" "}
                              <strong>once.</strong>
                            </p>
                          </CardBody>
                        </Card>

                        <Table className="mt-2 max-h-52">
                          <TableHeader>
                            <TableColumn key="from">Old URL</TableColumn>
                            <TableColumn key="to">New Url</TableColumn>
                            <TableColumn key="add">Add redirect</TableColumn>
                            <TableColumn key="error">Error</TableColumn>
                          </TableHeader>
                          <TableBody>
                            {auditLinks.map((value: any, index: any) => (
                              <TableRow key={index}>
                                <TableCell key={value?.oldUrl}>
                                  {value?.oldUrl}
                                </TableCell>
                                <TableCell key={value.newUrl}>
                                  {value?.newUrl}
                                </TableCell>
                                <TableCell key="add">
                                  <Switch
                                    aria-label="Add redirect"
                                    name="redirects"
                                    value={value?.id}
                                    onChange={setRedirects}
                                    isSelected={redirectSelected(value?.id)}
                                    isDisabled={slugExistsAsRedirect(
                                      value?.oldUrl,
                                      value?.newUrl
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  {slugExistsAsRedirect(
                                    value?.oldUrl,
                                    value?.newUrl
                                  ) && (
                                    <p>
                                      URL has an existing redirect. Remove this
                                      redirect before attempting to add another.
                                    </p>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <textarea
                          hidden
                          value={JSON.stringify(newRedirects)}
                          name="redirectsData"
                        ></textarea>
                      </div>
                    ) : (
                      <>
                        {valid && cmsLinks && <p>No new URLs.</p>}
                        {valid && !cmsLinks && <p>{`${siteLinks.length} page URLs to import.`}</p>}
                      </>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                {formInvalid && (
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => fetchLinks()}
                  >
                    Fetch URL data
                  </Button>
                )}
                {!formInvalid && (
                  <Button type="submit" color="primary">
                    {auditLinks.length > 0
                      ? "Update redirects"
                      : "Import link data"}
                  </Button>
                )}
                <Button variant="flat" onPress={() => close(false)}>
                  Close
                </Button>
              </ModalFooter>
            </Form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default StoryblokLinks;
