const GRAPHQL_URL = 'https://gapi.storyblok.com/v1/api';

export const STORYBLOK_LINKS_QUERY = `query StoryblokLinks {
  Links {
    items {
      id
      slug
    }
  }
}`;

export async function storyblokFetch(token: string) {
  const res = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Token: token,
      Version: 'published',
    } as any,
    body: JSON.stringify({ query: STORYBLOK_LINKS_QUERY }),
  });

  const json = await res.json();

  if (json.errors) {
    throw new Error('Failed to fetch API');
  }

  return json.data?.Links?.items || [];
}