"use client";

import React from "react";
import AddSiteForm from "../AddSiteForm/AddSiteForm";
import { Link, useLoaderData } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from "@nextui-org/react";

type LoaderData = {
  sites: Array<any>;
};

const Sites = () => {
  const { sites = [] } = useLoaderData() as LoaderData;

  return (
    <div className="max-w-100 mb-16 grid grid-cols-12 gap-5">
      {sites && sites.length < 1 ? (
        <div className="max-100 pr-2 pl-2 col-span-8">
          <h1 className="mb-3 text-l">
            You haven't got a site yet. Add a new site to get started.
          </h1>
          <AddSiteForm />
        </div>
      ) : (
        <>
          <Card className="max-100 pr-2 pl-2 col-span-8">
            <CardHeader>
              <h1 className="text-2xl mb-3">All Sites</h1>
            </CardHeader>
            <div className="pb-5">
              <Table shadow="none">
                <TableHeader>
                  <TableColumn key="name">name</TableColumn>
                  <TableColumn key="url">url</TableColumn>
                  <TableColumn key="redirects">redirects</TableColumn>
                </TableHeader>
                <TableBody items={sites}>
                  {(item: any) => (
                    <TableRow key={item.key}>
                      {(columnKey) => (
                        <TableCell>
                          {columnKey === "redirects" ? (
                            <>
                              <Link
                                to={`/${item.id}`}
                                className="bold"
                              >
                                View
                              </Link>
                            </>
                          ) : (
                            <>{getKeyValue(item, columnKey)}</>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Card>
          <div className="col-span-4">
            <AddSiteForm />
          </div>
        </>
      )}
    </div>
  );
};

export default Sites;
