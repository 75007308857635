"use client"
import { useState, useEffect } from 'react'
import { FaMoon, FaSun } from 'react-icons/fa';
import { useDarkModeProvider } from '../../lib/providers/DarkModeProvider'

const DarkModeButton = () => {
  const [mounted, setMounted] = useState(false)
  const { isDarkMode, disable, enable } = useDarkModeProvider();

  useEffect(() => {
    setMounted(true)
  }, [])

  // For now, disable light mode
  return null;

  if (!mounted) {
    return null
  }

  return (
    <button className="ml-5" onClick={e => isDarkMode ? disable() : enable()}>
        {isDarkMode ? <FaSun /> : <FaMoon />}
    </button>
  )
}

export default DarkModeButton