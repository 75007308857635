"use client";

import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from "@nextui-org/react";
import DarkModeButton from "../DarkModeButton/DarkModeButton";
import {
  FaAddressCard,
  FaAngleDoubleUp,
  FaBullhorn,
  FaGlobe,
  FaList,
} from "react-icons/fa";
import PasteModal from "../PasteModal/PasteModal";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLoaderData, useNavigate, useParams, Link } from "react-router-dom";

type LoaderData = {
  sites: Array<any>;
};

type Key = string | number;

const Header = () => {
  const [pasteModal, setPasteModal] = useState(false);
  const { sites = [] } = useLoaderData() as LoaderData;
  const params = useParams();

  const { signOut, authStatus, user } = useAuthenticator();
  const navigate = useNavigate();
  const activeSiteId = params?.siteId;
  const activeSite = sites.find((site) => site.id === activeSiteId);
  const sitesList = sites.filter((site) => site.id !== activeSiteId);
  const loggedIn = authStatus === "authenticated";

  const dropdownNavigate = (key: Key) => navigate(`${key}`);

  return (
    <div className="max-w-100 mb-16">
      <div className="flex items-center">
        <div className="mr-5">
          <Link to="/">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 24V6.5V0H6.5H24V2.95833L15 6.5H11.0968L23.7502 19.1534L19.154 23.7496L6.5 11.0956V15L2.95833 24H0Z"
                fill="#00EA25"
              />
            </svg>
          </Link>
        </div>
        <div className="mr-5">
          {sites && sites.length > 0 && (
            <Dropdown>
              <DropdownTrigger>
                <Button size="md">
                  <FaList />
                  {activeSite ? <h3>{activeSite.name}</h3> : <h3>My Sites</h3>}
                </Button>
              </DropdownTrigger>
              <DropdownMenu onAction={(key) => dropdownNavigate(key)}>
                <DropdownSection>
                  {sitesList.map((site) => (
                    <DropdownItem
                      startContent={<FaGlobe />}
                      key={`/${site.id}`}
                    >
                      <span className="">
                        {site.name}
                        <span className="ml-5 text-sm">{site.url}</span>
                      </span>
                    </DropdownItem>
                  ))}
                </DropdownSection>
                <DropdownSection>
                  <DropdownItem
                    key="/"
                    className="text-primary"
                    shortcut="⌘⇧D"
                    description="Add a new domain to specify redirects"
                    startContent={<FaAddressCard />}
                  >
                    Add new site
                  </DropdownItem>
                </DropdownSection>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {loggedIn && (
          <>
            <div className="ml-auto mr-0 flex">
              <span className="m-0 mr-3">{user?.attributes?.email}</span>
              <Button
                size="sm"
                onClick={() =>
                  signOut({ callbackUrl: "http://localhost:3000" })
                }
              >
                Sign Out
              </Button>
            </div>
            <Divider orientation="vertical" />
          </>
        )}
        <DarkModeButton />
      </div>
      <Divider className="my-4" />
    </div>
  );
};

export default Header;
