export const redirectTypes = [
  {
    label: 'Permanent',
    value: 'permanent'
  },
  {
    label: 'Temporary',
    value: 'temporary'
  }
]
