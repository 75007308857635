"use client";

import React, { useState, useCallback, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  getKeyValue,
  Button,
  Spinner,
  Input,
  ButtonGroup,
  Tabs,
  Tab,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Divider,
} from "@nextui-org/react";
import {
  FaArrowAltCircleRight,
  FaCompressArrowsAlt,
  FaSchlix,
  FaSearch,
} from "react-icons/fa";
import {
  Form,
  useLoaderData,
  useSearchParams,
  useSubmit,
} from "react-router-dom";
import AddRedirect from "../AddRedirect/AddRedirect";
import EditSite from "../EditSite/EditSite";
import StoryblokLinks from "../StoryblokLinks/StoryblokLinks";
import Export from "../Export/Export";

type Key = string | number;

type LoaderData = {
  site: any;
};

const RedirectsTable = () => {
  const { site } = useLoaderData() as LoaderData;
  const redirects = site.redirects;

  const [page, setPage] = useState(1);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] =
    useState<boolean>(false);

  const [filterValue, setFilterValue] = useState("");
  const [redirectType, setRedirectType] = useState<Key>("all");
  const [searchParams, setSearchParams] = useSearchParams();
  const submit = useSubmit();

  const [uiState, setUiState] = useState("");
  const deleteActionModal =
    searchParams.get("deleteAction") === "true" || showDeleteSuccessModal;

  const SourceCell = ({ author, value }: any) => {
    return (
      <>
        <div className="">{value}</div>
        {author}
      </>
    );
  };

  const PathCell = ({ path, subPaths }: any) => {
    return (
      <>
        {path}
        <div className="mt-2">
          {subPaths &&
            subPaths.map((subPath: string) => (
              <div key={subPath}>{subPath}</div>
            ))}
        </div>
      </>
    );
  };

  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = useMemo(() => {
    let filteredRedirects = [...redirects.items];

    if (hasSearchFilter) {
      filteredRedirects = filteredRedirects.filter((redirect: any) =>
        redirect.from.toLowerCase().includes(filterValue.toLowerCase())
      );
    }

    if (redirectType === "permanent") {
      filteredRedirects = filteredRedirects.filter(
        (redirect) => redirect.type === "permanent"
      );
    } else if (redirectType === "temporary") {
      filteredRedirects = filteredRedirects.filter(
        (redirect) => redirect.type === "temporary"
      );
    }

    return filteredRedirects;
  }, [filterValue, hasSearchFilter, redirects.items, redirectType]);

  const filteredPages = Math.ceil(filteredItems.length / 100);

  const onSearchChange = useCallback((value: any) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const closeDeleteModal = () => {
    setShowDeleteSuccessModal(false);
    setSearchParams();
  };

  if (redirects.items.length < 1) {
    return (
      <div>
        <AddRedirect />
        <p>You have no Redirects. Try adding one above.</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center flex-wrap">
        <div className="w-full flex items-center gap-4 mb-8">
          <Input
            isClearable
            className="w-full"
            placeholder="Search by slug..."
            startContent={<FaSearch />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
            size="lg"
          />
          <div className="ml-auto min-w-fit flex gap-2">
            <Button
              color="primary"
              onClick={() => setUiState("add")}
              variant={uiState === "add" ? "solid" : "ghost"}
              size="lg"
            >
              Add redirect
            </Button>
            <Button
              color="primary"
              onClick={() => setUiState("storyblokLinks")}
              variant={uiState === "storyblokLinks" ? "solid" : "ghost"}
              size="lg"
            >
              CMS Link Audit
            </Button>
          </div>
        </div>

        <Tabs
          className="ml-auto"
          selectedKey={redirectType}
          onSelectionChange={setRedirectType}
        >
          <Tab
            key="all"
            title={
              <div className="flex items-center space-x-2">
                <FaSchlix />
                <span>All</span>
              </div>
            }
          />
          <Tab
            key="permanent"
            title={
              <div className="flex items-center space-x-2">
                <FaCompressArrowsAlt />
                <span>Permanent</span>
              </div>
            }
          />
          <Tab
            key="temporary"
            title={
              <div className="flex items-center space-x-2">
                <FaArrowAltCircleRight />
                <span>Temporary</span>
              </div>
            }
          />
        </Tabs>
      </div>
      <div className="my-3">
        {uiState === "add" && <AddRedirect />}
        {uiState === "storyblokLinks" && (
          <StoryblokLinks visible close={() => setUiState("search")} />
        )}
      </div>
      <Table
        aria-label="Example table with client side pagination"
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="primary"
              variant="faded"
              page={page}
              total={filteredPages}
              onChange={(page) => setPage(page)}
            />
          </div>
        }
        classNames={{
          wrapper: "min-h-[222px]",
        }}
        /*
      // @ts-ignore */
        sortDescriptor={redirects.sortDescriptor}
        /*
      // @ts-ignore */
        onSortChange={redirects.sort}
      >
        <TableHeader>
          <TableColumn key="from" allowsSorting>
            FROM
          </TableColumn>
          <TableColumn key="to" allowsSorting>
            TO
          </TableColumn>
          <TableColumn key="source" allowsSorting>
            SOURCE
          </TableColumn>
          <TableColumn key="details" className="text-right">
            Actions
          </TableColumn>
        </TableHeader>
        <TableBody
          items={filteredItems}
          isLoading={false}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item: any) => (
            <TableRow key={item.key}>
              {(columnKey) => (
                <TableCell className="align-top border-b-1 border-silver-400 py-3">
                  <>
                    {(columnKey === "from" || columnKey === "to") && (
                      <PathCell path={getKeyValue(item, columnKey)} />
                    )}

                    {columnKey === "source" && (
                      <SourceCell
                        value={getKeyValue(item, columnKey)}
                        author={getKeyValue(item, "author")}
                      />
                    )}

                    {columnKey === "details" && (
                      <>
                        <div className="relative flex justify-end gap-2">
                          <Form
                            method="delete"
                            onSubmit={(e) => {
                              e.preventDefault();
                              submit(e.currentTarget, {
                                method: "DELETE",
                                action: `/${site.id}/${item.id}`,
                                replace: false,
                              });
                            }}
                            action={`/${site.id}/${item.id}`}
                            reloadDocument={false}
                          >
                            <input
                              type="hidden"
                              value={item.id}
                              name="redirectId"
                              id="redirectId"
                            />
                            <input
                              type="hidden"
                              value={site.id}
                              name="siteId"
                              id="siteId"
                            />
                            <Button type="submit" variant="flat">
                              Delete
                            </Button>
                          </Form>
                        </div>
                      </>
                    )}
                  </>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="grid grid-cols-[600px_1fr] gap-4 my-8">
        <Card>
          <CardHeader>
            <h4>Integration</h4>
          </CardHeader>
          <Divider />
          <CardBody>
            <Export siteId={site.id} thisSiteKey={site.accessKey} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h4>Site Management</h4>
          </CardHeader>
          <Divider />
          <CardBody>
            <EditSite site={site} />
          </CardBody>
        </Card>
      </div>
      <Modal
        isOpen={deleteActionModal}
        onOpenChange={() => closeDeleteModal()}
        placement="top-center"
        backdrop="blur"
        size="lg"
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">
            Redirect deleted
          </ModalHeader>
          <ModalFooter>
            <Button variant="flat" onPress={() => closeDeleteModal()}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RedirectsTable;
