// components/Login.js
import { useEffect } from "react";

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';
import { Button } from "@nextui-org/react";

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <View className="auth-wrapper grid grid-cols-2 gap-4 mt-20">
      <div className="w-full">
        <svg
          width="100"
          height="100"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 24V6.5V0H6.5H24V2.95833L15 6.5H11.0968L23.7502 19.1534L19.154 23.7496L6.5 11.0956V15L2.95833 24H0Z"
            fill="#00EA25"
          />
        </svg>
        <h1 className="mt-10 text-3xl">Login to Redirectionality</h1>
      </div>
      <Authenticator></Authenticator>
    </View>
  );
}

export default Login;
