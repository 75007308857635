import React, { useEffect, useState } from "react";
import { Input } from "@nextui-org/input";
import { Button } from "@nextui-org/react";
import { FaCrosshairs } from "react-icons/fa";
import { Form } from "react-router-dom";
import { UpdateSiteInput } from "../../API";

interface IEditSiteProps {
  site: UpdateSiteInput;
}

const EditSite = ({ site }: IEditSiteProps) => {
  const { name, url, storyblokSecret, buildHook } = site;
  const [response, setResponse] = useState(null);

  const pingURL = async () => {
    if (!buildHook) return false;

    try {
      const result = await fetch(
        `${buildHook}/?trigger_branch=main&trigger_title=triggered+by+Redirectionality&clear_cache=true`,
        {
          method: "POST",
        }
      );
      const data = await result.json();
      setResponse(data); // If you want to do something with the response
    } catch (error) {
      console.error("Error pinging URL:", error);
    }
  };

  return (
    <Form
      className="flex w-full flex-wrap items-center gap-4 mb-4"
      method="POST"
    >
      <div className="w-full">
        <Input
          type="text"
          label="Name"
          placeholder="Enter site name"
          name="name"
          id="name"
          defaultValue={url || ""}
        />
      </div>

      <div className="w-full">
        <Input
          type="text"
          label="URL"
          placeholder="Enter site URL"
          name="url"
          id="url"
          defaultValue={name || ""}
        />
      </div>

      <Input
        type="text"
        label="Build Hook"
        placeholder="Enter a build hook URL"
        name="buildHook"
        id="buildHook"
        defaultValue={buildHook || ""}
      />

      {/* <Input
        type="text"
        label="Storyblok Webhook Secret"
        placeholder="Enter a Storyblok webhook secret"
        name="storyblokSecret"
        id="storyblokSecret"
        defaultValue={storyblokSecret}
      /> */}

      {buildHook && (
        <Button
          color="primary"
          variant="ghost"
          type="button"
          size="lg"
          className="min-w-fit ml-auto mt-2"
          onClick={pingURL}
        >
          Build site
          <FaCrosshairs size={18} />
        </Button>
      )}

      <Button
        color="primary"
        variant="solid"
        type="submit"
        size="lg"
        className="min-w-fit ml-2 mt-2"
      >
        Update
        <FaCrosshairs size={18} />
      </Button>

      {response && (
        <div>
          <p>Response:</p>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
    </Form>
  );
};

export default EditSite;
