import { Button, Snippet } from "@nextui-org/react";
import { useState } from "react";
import { generateToken } from "../../graphql/mutations";
import { API } from "aws-amplify";
import awsmobile from "../../aws-exports";

interface IExportProps {
  siteId: string;
  thisSiteKey: string;
}

const Export = ({ siteId, thisSiteKey }: IExportProps) => {
  const [token, setToken] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCode, setShowCode] = useState<boolean>(false);

  const displayToken = token || thisSiteKey;

  const keyGenerator = async () => {
    setIsLoading(true);

    const doGenToken = async () => {
      try {
        // Generate the token
        const addTokenResult: any = await API.graphql({
          query: generateToken,
          authMode: "AMAZON_COGNITO_USER_POOLS",
          variables: {
            siteId: siteId,
          },
        });

        setToken(addTokenResult?.data?.generateToken);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    return doGenToken();
  };

  const keyDisplay = (key: string) => {
    return `${key.substring(0, 60)}...`;
  };

  const CopyButton = () => {
    return (
      <button className="group inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 gap-unit-2 rounded-small px-unit-0 !gap-unit-0 data-[pressed=true]:scale-[0.97] transition-transform-colors motion-reduce:transition-none bg-transparent min-w-unit-8 w-unit-8 h-unit-8 group relative z-10 text-large text-inherit data-[hover=true]:bg-transparent">
        Copy
      </button>
    );
  };

  return (
    <>
      {displayToken ? (
        <>
          <div>
            <p className="mt-3 mb-3 w-full">Site authorization token:</p>
            <div className="w-full inline-flex items-center justify-between h-fit gap-2 px-3 py-1.5 text-small rounded-medium bg-default/40 text-default-foreground">
              <pre className="w-full whitespace-pre-wrap bg-transparent text-inherit font-mono font-normal inline-block">
                {displayToken}
              </pre>
            </div>
            <Button className="mt-4" onClick={() => keyGenerator()}>
              Regenerate key
            </Button>
            <Button color="secondary" className="ml-2 mt-4" onClick={() => setShowCode(!showCode)}>
              {showCode ? 'Hide code' : 'View code'}
            </Button>
          </div>
          {showCode && (
            <>
              <div className="flex mt-3 flex-wrap">
                <p className="mt-3 mb-1 w-full">
                  Pass the token above as an authorization header.
                </p>
                <p className="mt-3 mb-1 w-full">GraphQL Endpoint:</p>
                <div className="w-full inline-flex items-center justify-between h-fit gap-2 px-3 py-1.5 text-small rounded-medium bg-default/40 text-default-foreground">
                  <pre className="w-full whitespace-pre-wrap bg-transparent text-inherit font-mono font-normal inline-block">
                    {awsmobile?.aws_appsync_graphqlEndpoint}
                  </pre>
                </div>
              </div>
              <div className="flex flex-wrap">
                <p className="mt-3 mb-1 w-full">Query:</p>
                <div className="w-full inline-flex items-center justify-between h-fit gap-2 px-3 py-1.5 text-small rounded-medium bg-default/40 text-default-foreground">
                  <pre className="w-full bg-transparent text-inherit font-mono font-normal inline-block">
                    {`query {
    getSite(id: "${siteId}") {
        redirects {
            items {
                id
                from
                to
                source
                type
            }
        }
    }
}`}
                  </pre>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="max-w-[200px]">
          <Button onClick={() => keyGenerator()}>Generate access key</Button>
        </div>
      )}
    </>
  );
};

export default Export;
