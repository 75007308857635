import createSafeContext from "../../lib/useSafeContext";
import { useDarkMode } from 'usehooks-ts';

export const [useDarkModeProvider, Provider] = createSafeContext<{
  isDarkMode: boolean;
  toggle: () => void;
  enable: () => void;
  disable: () => void;
}>();

export const DarkModeProvider = ({ children }: any) => {
  const { isDarkMode, toggle, enable, disable } = useDarkMode(true);

  const providerValue = {
    isDarkMode,
    toggle,
    enable,
    disable
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
