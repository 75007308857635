/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://04g7m1xe1f.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "redirectAction",
            "endpoint": "https://y3uokhsk8d.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://f3gk3l4rhjgzto7zp4cwbqy6ea.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_cognito_identity_pool_id": "eu-west-1:da2c85fc-861c-4e89-a6a2-fefb48ccabb4",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_OyfJxvd1C",
    "aws_user_pools_web_client_id": "1hgkp15ta2o8kie83hg2qaqbq3",
    "oauth": {
        "domain": "redirectionality83599b53-83599b53-develop.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://develop.dx7bh5a4hmk5c.amplifyapp.com/,https://develop.dx7bh5a4hmk5c.amplifyapp.com/,http://localhost:3000/",
        "redirectSignOut": "https://develop.dx7bh5a4hmk5c.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
