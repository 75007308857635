import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";

import "./App.css";
import { DarkModeProvider } from "./lib/providers/DarkModeProvider";
import RouterComponent from "./lib/Router";

Amplify.configure(awsExports);

function App({ signOut, user }) {
  return (
    <>
      <Authenticator.Provider>
        <DarkModeProvider>
            <RouterComponent />
        </DarkModeProvider>
      </Authenticator.Provider>
    </>
  );
}

export default App;
