import React, { useEffect } from "react";
import { Input } from "@nextui-org/input";
import { Button, Select, SelectItem } from "@nextui-org/react";
import { FaCrosshairs, FaPaste } from "react-icons/fa";
import { useState } from "react";
import { redirectTypes } from "./data";
import ErrorModal from "../ErrorModal/ErrorModal";
import { Form, useActionData, useLoaderData } from "react-router-dom";
import PasteModal from "../PasteModal/PasteModal";
import { useAuthenticator } from "@aws-amplify/ui-react";

type LoaderData = {
  site: any;
};

const AddRedirect = () => {
  const { site } = useLoaderData() as LoaderData;
  const redirects = site.redirects;

  const { user } = useAuthenticator();
  const [error, setError] = useState(false);
  const [pasteModalVisible, setPasteModalVisible] = useState(false);
  const actionData = useActionData() as any;

  useEffect(() => {
    if (actionData?.ruleAlreadyExists) {
      setError(true);
    }
  }, [actionData]);

  return (
    <Form
      className="flex w-full flex-wrap items-center md:flex-nowrap gap-4 mb-4"
      method="PUT"
    >
      <Input
        type="text"
        label="From"
        placeholder="Enter from URL"
        name="from"
        id="from"
        isInvalid={actionData?.from}
        errorMessage={actionData?.from}
      />

      <Input
        type="text"
        label="To"
        placeholder="Enter to URL"
        name="to"
        id="to"
        isInvalid={actionData?.to}
        errorMessage={actionData?.to}
      />

      <Select
        label="Type"
        placeholder="Select redirection type"
        className="max-w-xs"
        name="type"
        id="type"
        isInvalid={actionData?.type}
        errorMessage={actionData?.type}
        defaultSelectedKeys={["permanent"]}
      >
        {redirectTypes.map((redirectType) => (
          <SelectItem
            key={redirectType.value}
            value={redirectType.value}
          >
            {redirectType.label}
          </SelectItem>
        ))}
      </Select>

      <Button
        color="primary"
        variant="ghost"
        type="button"
        size="lg"
        className="min-w-fit"
        onClick={(e) => {
          e.preventDefault();
          setPasteModalVisible(true);
        }}
      >
        <FaPaste />
        Paste from CSV
      </Button>

      {redirects?.items && redirects?.items.length > 0 && (
        <input
          type="hidden"
          name="redirects"
          id="redirects"
          value={JSON.stringify(redirects.items)}
        />
      )}

      <input
        type="hidden"
        name="author"
        id="author"
        value={user?.attributes?.email}
      />

      <input type="hidden" name="source" id="source" value="manual" />

      <Button
        color="primary"
        variant="solid"
        type="submit"
        size="lg"
        className="min-w-fit"
      >
        Add
        <FaCrosshairs size={18} />
      </Button>

      <ErrorModal visible={error} close={setError} />
      <PasteModal visible={pasteModalVisible} close={setPasteModalVisible} />
    </Form>
  );
};

export default AddRedirect;
