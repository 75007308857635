// components/Layout.js
import React from "react";
import { Outlet } from "react-router-dom";
import "./Application.css";
import { useDarkModeProvider } from "../lib/providers/DarkModeProvider";
import { NextUIProvider } from "@nextui-org/react";
import RequireAuth from "../lib/RequireAuth";
import Header from "../components/Header/Header";

const Application = ({ children }: any) => {
  const { isDarkMode } = useDarkModeProvider();
  return (
    <div
      className={
        isDarkMode
          ? `dark App min-h-screen text-foreground bg-background`
          : "App min-h-screen text-foreground bg-background"
      }
    >
      <div className="layout-container  p-8">
        <div className="page-container">
          <RequireAuth>
            <NextUIProvider>
              <Header />
              <Outlet />
              {children}
            </NextUIProvider>
          </RequireAuth>
        </div>
      </div>
    </div>
  );
};

export default Application;
