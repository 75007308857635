import { fetchRedirect, fetchRedirects } from "./redirects";
import { fetchSite, fetchSites } from "./sites";


export const sitesRedirectsLoader = async (siteId: string) => {
  const site = await fetchSite(siteId);
  const redirects = await fetchRedirects(siteId);
  return {
    redirects,
    site
  };
}

export const sitesLoader = async () => {
  const sites = await fetchSites();
  return {
    sites
  };
}

export const redirectLoader = async (redirectId: string) => {
  const sites = await fetchSites();
  const redirect = await fetchRedirect(redirectId);
  return {
    sites,
    redirect
  };
}

