import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { FaMailBulk, FaPassport } from "react-icons/fa";

interface IErrorModalProps {
  visible: boolean,
  close: (open: boolean) => void
}

const ErrorModal = ({ visible, close }: IErrorModalProps) => {
  return (
    <>
      <Modal isOpen={visible} onOpenChange={close} placement="top-center" backdrop="blur">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Error!</ModalHeader>
              <ModalBody>

                <div className="flex py-2 px-1 justify-between">
                  <p>Matching rule already exists.</p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={() => close(false)}>
                  Close
                </Button>

              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ErrorModal;
