import React, { useState, useRef } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Table,
  TableRow,
  TableCell,
  TableHeader,
  TableColumn,
  TableBody,
} from "@nextui-org/react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { batchAddRedirects } from "../../graphql/mutations";
import { API } from "aws-amplify";
import { useLoaderData } from "react-router-dom";

interface IPasteModalProps {
  visible: boolean;
  close: (open: boolean) => void;
}

type LoaderData = {
  site: any;
};

const PasteModal = ({ visible, close }: IPasteModalProps) => {
  const { site } = useLoaderData() as LoaderData;
  const siteId = site.id;

  const [valid, setValid] = useState(true);
  const [formVals, setFormVals] = useState([]);
  const { user } = useAuthenticator();
  const formRef = useRef(null);

  const CSVToArray = (strData: string, strDelimiter: string) => {
    strDelimiter = strDelimiter || ",";

    if (!strData) return [];

    var objPattern = new RegExp(
      // Delimiters.
      "(\\" +
        strDelimiter +
        "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        "\\r\\n]*))",
      "gi"
    );

    var arrData = [];
    var arrMatches = null;

    while ((arrMatches = objPattern.exec(strData))) {
      let strMatchedValue;

      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        strMatchedValue = arrMatches[3];
      }

      const splitVals = strMatchedValue.split(/(\s+)/);
      const redirectFrom = splitVals[0];
      const redirectTo = splitVals[2];
      const redirectType = splitVals[4] || "permanent";

      arrData.push({
        key: `${redirectFrom}:${redirectTo}`,
        from: redirectFrom,
        to: redirectTo,
        type: redirectType,
        source: "Manual",
        author: user?.attributes?.email,
      });
    }

    // Return the parsed data.
    return arrData;
  };

  const rulesOnBlur = (e: any) => {
    e.preventDefault();

    const arrayVals = CSVToArray(e.target.value, ",");
    const validArray = arrayVals.every((redirect) => {
      return (
        redirect.hasOwnProperty("from") &&
        typeof redirect.from === "string" &&
        redirect.hasOwnProperty("to") &&
        typeof redirect.to === "string"
      );
    });

    setValid(validArray);
    // @ts-ignore
    setFormVals(arrayVals);
  };

  const formSubmit = async (e: any) => {
    e.preventDefault();

    const formValsData = formVals.map((formVal: any) => {
      return {
        from: formVal.from,
        to: formVal.to,
        source: formVal.source,
        type: formVal.type,
        author: formVal.author,
        siteRedirectsId: siteId,
      }
    })

    try {
      const addRedirectsResult = await API.graphql({
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        query: batchAddRedirects,
        variables: {
          redirects: formValsData,
        },
      });
    
      return addRedirectsResult;
    } catch (error) {
      
    }

    close(false);
  };

  return (
    <>
      <Modal
        isOpen={visible}
        onOpenChange={close}
        placement="top-center"
        backdrop="blur"
        size="lg"
      >
        <ModalContent>
          {(onClose) => (
            <form onSubmit={formSubmit} ref={formRef}>
              <ModalHeader className="flex flex-col gap-1">
                Paste redirect rules
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-wrap gap-2 py-2 px-1 justify-between">
                  <div className="w-full pt-2">
                    <p>
                      Copy from a Google street and paste below. Ensure your
                      table is setup as three columns in the format of
                      From/To/Type.
                    </p>
                  </div>
                  <div className="w-full pt-2">
                    <Textarea
                      variant="bordered"
                      name="pasterules"
                      id="pasterules"
                      label="Rules"
                      labelPlacement="outside"
                      placeholder={`/redirect-from  /redirect-to  permanent
/redirect-from  /redirect-to  temporary`}
                      isInvalid={!valid}
                      errorMessage={
                        !valid
                          ? "Ensure redirects follow the specified format"
                          : ""
                      }
                      onBlur={rulesOnBlur}
                      onChange={rulesOnBlur}
                    ></Textarea>
                  </div>
                  <div className="w-full pt-2">
                    {formVals && formVals.length > 0 && (
                      <div>
                        <p className="pb-2">Preview:</p>
                        <Table className="max-h-52">
                          <TableHeader>
                            <TableColumn key="from">FROM</TableColumn>
                            <TableColumn key="to">TO</TableColumn>
                            <TableColumn key="type">TYPE</TableColumn>
                          </TableHeader>
                          <TableBody>
                            {formVals.map((value: any, index: any) => (
                              <TableRow key={index}>
                                <TableCell key={value?.from}>
                                  {value?.from}
                                </TableCell>
                                <TableCell key={value.to}>
                                  {value?.to}
                                </TableCell>
                                <TableCell key={value.type}>
                                  {value?.type}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Submit
                </Button>
                <Button variant="flat" onPress={() => close(false)}>
                  Close
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default PasteModal;
