import { API } from "aws-amplify";
import { getSite, listRedirects, listSites } from "../../graphql/queries";
import {
  batchAddRedirects,
  createRedirect,
  createSite,
  updateSite,
} from "../../graphql/mutations";

export const fetchSites = async () => {
  const apiData: any = await API.graphql({
    query: listSites,
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });

  return apiData?.data?.listSites?.items;
};

export const fetchSite = async (siteId: string) => {
  const apiData: any = await API.graphql({
    query: getSite,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      id: siteId
    }
  });

  return apiData?.data?.getSite;
};

export const createSiteAction = async ({ name, url }: any) => {
  if (!name) {
    return {
      createSiteError: "Missing name",
    };
  }

  if (!url) {
    return {
      createSiteError: "Missing url",
    };
  }

  const addSiteResult = await API.graphql({
    authMode: "AMAZON_COGNITO_USER_POOLS",
    query: createSite,
    variables: {
      input: {
        name: name,
        url: url,
      },
    },
  });

  return addSiteResult;
};

export const editSiteAction = async ({
  name,
  url,
  buildHook,
  siteId,
  storyblokSecret,
  cmsType,
  cmsLinks,
  cmsToken,
  redirects,
  author,
}: any) => {
  if (!siteId) {
    return {
      editSiteError: "Missing siteId",
    };
  }

  if (!name) {
    return {
      editSiteError: "Missing name",
    };
  }

  if (!url) {
    return {
      editSiteError: "Missing url",
    };
  }

  const editSiteResult = await API.graphql({
    authMode: "AMAZON_COGNITO_USER_POOLS",
    query: updateSite,
    variables: {
      input: {
        id: siteId,
        name: name,
        url: url,
        cmsType: cmsType,
        cmsLinks: cmsLinks,
        cmsToken: cmsToken,
        buildHook: buildHook,
        storyblokSecret: storyblokSecret,
      },
    },
  });

  let redirectsArr = [];

  try {
    redirectsArr = JSON.parse(redirects)
  } catch {}

  console.log('redirectsArr', redirectsArr);

  if (redirectsArr.length > 0) {
    const redirectsArrData = redirectsArr.map((redirect: any) => {
      return {
        from: redirect.oldUrl,
        to: redirect.newUrl,
        source: 'manual',
        type: 'permanent',
        author: author,
        siteRedirectsId: siteId,
      }
    })

    const batchRedirectsResult = await API.graphql({
      authMode: "AMAZON_COGNITO_USER_POOLS",
      query: batchAddRedirects,
      variables: {
        redirects: redirectsArrData
      }
    });

    console.log('batchRedirectsResult', batchRedirectsResult)
  }

  return editSiteResult;
};
