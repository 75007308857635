/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      name
      url
      redirects {
        items {
          id
          from
          to
          source
          type
          author
          createdAt
          updatedAt
          siteRedirectsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accessKey
      storyblokSecret
      cmsToken
      cmsType
      cmsLinks
      buildHook
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      name
      url
      redirects {
        items {
          id
          from
          to
          source
          type
          author
          createdAt
          updatedAt
          siteRedirectsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accessKey
      storyblokSecret
      cmsToken
      cmsType
      cmsLinks
      buildHook
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      name
      url
      redirects {
        items {
          id
          from
          to
          source
          type
          author
          createdAt
          updatedAt
          siteRedirectsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      accessKey
      storyblokSecret
      cmsToken
      cmsType
      cmsLinks
      buildHook
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRedirect = /* GraphQL */ `
  mutation CreateRedirect(
    $input: CreateRedirectInput!
    $condition: ModelRedirectConditionInput
  ) {
    createRedirect(input: $input, condition: $condition) {
      id
      site {
        id
        name
        url
        redirects {
          nextToken
          __typename
        }
        accessKey
        storyblokSecret
        cmsToken
        cmsType
        cmsLinks
        buildHook
        createdAt
        updatedAt
        owner
        __typename
      }
      from
      to
      source
      type
      author
      createdAt
      updatedAt
      siteRedirectsId
      owner
      __typename
    }
  }
`;
export const updateRedirect = /* GraphQL */ `
  mutation UpdateRedirect(
    $input: UpdateRedirectInput!
    $condition: ModelRedirectConditionInput
  ) {
    updateRedirect(input: $input, condition: $condition) {
      id
      site {
        id
        name
        url
        redirects {
          nextToken
          __typename
        }
        accessKey
        storyblokSecret
        cmsToken
        cmsType
        cmsLinks
        buildHook
        createdAt
        updatedAt
        owner
        __typename
      }
      from
      to
      source
      type
      author
      createdAt
      updatedAt
      siteRedirectsId
      owner
      __typename
    }
  }
`;
export const deleteRedirect = /* GraphQL */ `
  mutation DeleteRedirect(
    $input: DeleteRedirectInput!
    $condition: ModelRedirectConditionInput
  ) {
    deleteRedirect(input: $input, condition: $condition) {
      id
      site {
        id
        name
        url
        redirects {
          nextToken
          __typename
        }
        accessKey
        storyblokSecret
        cmsToken
        cmsType
        cmsLinks
        buildHook
        createdAt
        updatedAt
        owner
        __typename
      }
      from
      to
      source
      type
      author
      createdAt
      updatedAt
      siteRedirectsId
      owner
      __typename
    }
  }
`;
export const batchAddRedirects = /* GraphQL */ `
  mutation BatchAddRedirects($redirects: [CreateRedirectInput]) {
    batchAddRedirects(redirects: $redirects) {
      id
      site {
        id
        name
        url
        redirects {
          nextToken
          __typename
        }
        accessKey
        storyblokSecret
        cmsToken
        cmsType
        cmsLinks
        buildHook
        createdAt
        updatedAt
        owner
        __typename
      }
      from
      to
      source
      type
      author
      createdAt
      updatedAt
      siteRedirectsId
      owner
      __typename
    }
  }
`;
export const generateToken = /* GraphQL */ `
  mutation GenerateToken($siteId: String) {
    generateToken(siteId: $siteId)
  }
`;
