
import Application from "../layouts/Application";
import RedirectsTable from "../components/RedirectsTable/RedirectsTable";
import { redirectLoader, sitesLoader, sitesRedirectsLoader } from "./data";
import {
  createRedirectAction,
  deleteRedirectAction,
} from "./data/redirects";
import Sites from "../components/Sites/Sites";
import { createSiteAction, editSiteAction } from "./data/sites";

import { RouterProvider, createBrowserRouter, redirect } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

const RouterComponent = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const authenticated = route === 'authenticated';

  const router = createBrowserRouter([
    {
      path: "/",
      loader: authenticated ? sitesLoader : () => [],
      element: <Application />,
      children: [
        {
          path: "/",
          loader: authenticated ? sitesLoader : () => [],
          element: <Sites />,
          action: async ({ request, params }) => {
            switch (request.method) {
              case "PUT": {
                const formData = await request.formData();
                const name = formData.get("name");
                const url = formData.get("url");
  
                return createSiteAction({ name, url });
              }
              case "DELETE": {
                break;
              }
              default: {
              }
            }
  
            return "Test";
          },
        },
        {
          path: "/:siteId",
          loader: ({ params }) => {
            return sitesRedirectsLoader(params.siteId as string);
          },
          action: async ({ request, params }) => {
            switch (request.method) {
              case "PUT": {
                // add a redirect
                const formData = await request.formData();
                const to = formData.get("to");
                const from = formData.get("from");
                const source = formData.get("source");
                const type = formData.get("type");
                const redirects = formData.get("redirects");
                const author = formData.get("author");
  
                return createRedirectAction({
                  to,
                  from,
                  source,
                  type,
                  author,
                  siteId: params?.siteId,
                  redirects,
                });
              }
              case "DELETE": {
                // delete site
                break;
              }
              case "POST": {
                // update site
                const formData = await request.formData();
                const name = formData.get("name");
                const url = formData.get("url");
                const cmsType = formData.get("cmsType");
                const cmsLinks = formData.get("cmsLinks");
                const cmsToken = formData.get("cmsToken");
                const storyblokSecret = formData.get("storyblokSecret");
                const redirects = formData.getAll("redirectsData");
                const author = formData.get("author");
                const buildHook = formData.get("buildHook");
  
                return editSiteAction({
                  name,
                  url,
                  cmsType,
                  cmsLinks,
                  cmsToken,
                  siteId: params?.siteId,
                  storyblokSecret,
                  redirects,
                  author,
                  buildHook,
                });
              }
              default: {
              }
            }
  
            return "Test";
          },
          element: (
            <>
              <RedirectsTable />
            </>
          ),
        },
        {
          path: "/:siteId/:redirectId",
          loader: ({ params }) => {
            return redirectLoader(params.redirectId as string);
          },
          action: async ({ request, params }) => {
            switch (request.method) {
              case "DELETE": {
                // add a redirect
                const formData = await request.formData();
                const redirectId = formData.get("redirectId");
                const siteId = formData.get("siteId");
  
                // delete redirect
                const deleteResult = await deleteRedirectAction({ redirectId });
                console.log('deleteResult', deleteResult)
                return redirect(`/${siteId}?deleteAction=true`);
              }
              default: {
              }
            }
  
            return "Test";
          },
          element: (
            <>
                <p>Edit</p>
            </>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default RouterComponent;
